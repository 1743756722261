// src/page/DatabaseImport.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./DatabaseImport.css";

const DatabaseImport = () => {
  const [file, setFile] = useState(null);
  const [filePath, setFilePath] = useState(""); // 新增状态用于保存文件路径

  const handleDatabaseImport = async () => {
    if (!filePath) {
      alert("请先上传文件");
      return;
    }
    try {
      const response = await axios.post(
        "https://barcodetest.saitys.com/api/import-barcodes",
        { filePath } // 将文件路径作为请求体传递
      );
      console.log("Database import successful:", response.data);
      alert("Database import successful");
    } catch (error) {
      console.error("Error importing database:", error);
      alert("Error importing database");
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = () => {
    if (!file) {
      alert("请选择文件");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    fetch("https://barcodetest.saitys.com/api/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        alert("文件上传成功: " + data.message);
        setFilePath(data.filePath); // 将文件路径保存到状态中
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        alert("文件上传失败");
      });
  };

  const ScannedCodeExport = async () => {
    try {
      const response = await axios.get(
        "https://barcodetest.saitys.com/api/scanned-barcodes"
      );
      const data = response.data;

      // Convert JSON data to CSV format
      const headers = Object.keys(data[0]).join(",") + "\n"; // CSV headers
      const rows = data.map((row) => Object.values(row).join(",")).join("\n"); // CSV rows
      const csvContent = headers + rows;

      // Create a Blob for CSV
      const blob = new Blob([csvContent], { type: "text/csv" });

      // Create a download link
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "scanned_barcodes.csv"; // File name
      link.click();

      // Cleanup
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting scanned codes:", error);
      alert("Error exporting scanned codes");
    }
  };

  const handleFileDownload = () => {
    if (!filePath) {
      alert("没有找到要下载的文件");
      return;
    }

    const filename = filePath.split("/").pop();
    const url = `https://barcodetest.saitys.com/api/download?filename=${filename}`;

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const fetchLatestFile = async () => {
      try {
        const response = await axios.get(
          "https://barcodetest.saitys.com/api/latest-upload"
        );
        const latestFilename = response.data.filename;
        setFilePath(`/tmp/${latestFilename}`);
      } catch (err) {
        console.warn("没有找到最新上传的文件", err);
      }
    };

    fetchLatestFile();
  }, []);

  return (
    <div className="database-import-container">
      <h2 className="page-title">文件上传与数据库导入</h2>

      <div>
        <input type="file" onChange={handleFileChange} />
        <button onClick={handleFileUpload} style={{ marginRight: "15px" }}>
          上传文件
        </button>
        <button onClick={handleFileDownload}>下载文件</button>
      </div>

      <div className="import">
        <button onClick={handleDatabaseImport}>导入数据库</button>
      </div>
      <div className="import">
        <button onClick={ScannedCodeExport}>导出扫描的数据</button>
      </div>
    </div>
  );
};

export default DatabaseImport;
